import React from "react";
import { keyframes, styled } from "styled-components";

const LoadingScreen = () => {
  const BodyDiv = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: #ffffffcb;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  `;
  const rotate = keyframes`
    from{
        transform: rotate(0deg);
    }

    to{
        transform: rotate(360deg);
    }
  `;

  const Rotate = styled.div`
  display: inline-block;
  animation: ${rotate} 4s linear infinite;
  padding : 1rem 1rem
  
  `

  return (
    <>
      <div>
        <BodyDiv>
          <Rotate>
            <img
              src="/images/schoolLogo.png"
              alt="Logo"
              style={{ width: "120px", height: "120px" }}
            />
          </Rotate>
        </BodyDiv>
      </div>
    </>
  );
};

export default LoadingScreen;
