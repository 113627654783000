const starOfMonthData = [
    {
        id: 'sep',
        title: '九月之星',
        etitle: 'Stars of September',
        thumbnail:
            'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-sep/f1.jpg',
        students: [
            {
                from: 1,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-sep/f1.jpg',
                studentName: ['1A - 李卓恒', '1B - 禇金鎮', 'IQRA CHAUDHRY'],
            },
            {
                from: 2,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-sep/f2.JPG',
                studentName: [
                    '2A - 黃子謙',
                    '2B - FATIMA AAYAN',
                ],
            },
            {
                from: 3,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-sep/f3.JPG',
                studentName: [
                    '3A - 阮曉君',
                    '3B - 盧建宇',
                    '3C - PUN GURUNG SUDESHNA',
                ],
            },
            {
                from: 4,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-sep/f4.JPG',
                studentName: ['4A - 黃依藍', '4B - 林子皓', '4C - TUZON CHARISSE YSABELLE PALACAT'],
            },
            {
                from: 5,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-sep/f5.JPG',
                studentName: [
                    '5A - 曾雅婷',
                    '5B - 郭金慧',
                    '5C - USMAN MUHAMMAD ALI'
                ],
            },
            {
                from: 6,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-sep/f6.JPG',
                studentName: ['6A - 施嘉星', '6B - SAAD ALI MUHAMMAD'],
            },
        ],
    },
    {
        id: 'oct',
        title: '十月之星',
        etitle: 'Stars of October',
        thumbnail:
            'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-oct/f1.jpg',
        // https://drive.google.com/file/d/1UZPwIoTzFFtKr_K5YElCTxiCfSi5cxlC/view?usp=share_link
        students: [
            {
                from: 1,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-oct/f1.jpg',
                studentName: ['1A - 陳思思', '1B - 吳晓藍', '1C - LIMBU LALGIE'],
            },
            {
                from: 2,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-oct/f2.jpg',
                studentName: [
                    '2A - 黃芷萱',
                    '2B - SIAKI MAIKELI LAGILAGI',
                ],
            },
            {
                from: 3,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-oct/f3.jpg',
                studentName: [
                    '3A - 陳冠峯',
                    '3B - 鄧梓標',
                    '3C - PUN RAZ',
                ],
            },  
            {
                from: 4,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-oct/f4.jpg',
                studentName: ['4A - 徐健鋒', '4B - 王祖迪', '4C-GURUNG ASHESH'],
            },
            {
                from: 5,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-oct/f5.jpg',
                studentName: [
                    '5A - 薛欣純',
                    '5B - 陳嘉琪',
                    '5C - MALIK HINA'
                ],
            },
            {
                from: 6,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-oct/f6.jpg',
                studentName: [
                    '6A - 施鴻毅',
                    '6B - GURUNG ALBIN',
                ],
            },
        ],
    },
    {
        id: 'nov',
        title: '十一月之星',
        etitle: 'Stars of Nov',
        thumbnail:
            'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-nov/f1.jpg',
        // https://drive.google.com/file/d/1UZPwIoTzFFtKr_K5YElCTxiCfSi5cxlC/view?usp=share_link
        students: [
            {
                from: 1,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-nov/f1.jpg',
                studentName: ['1A - 万淳明', '1B - 張杞悠', '1C - ASIF NABA'],
            },
            {
                from: 2,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-nov/f2.jpg',
                studentName: [
                    '2A - 陳志勇',
                    '2B - TABUAC PAMELA KARA JACOB',
                ],
            },
            {
                from: 3,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-nov/f3.jpg',
                studentName: [
                    '3A - 李浚杰',
                    '3B - 盧穎詩',
                    '3C - ZOSA ELDAYNE LEIGH DEL PARDO',
                ],
            },
            {
                from: 4,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-nov/f4.jpg',
                studentName: ['4A - 盧曉龍', '4B - 張池霆', '4C-RANA RIVA'],
            },
            {
                from: 5,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-nov/f5.jpg',
                studentName: [
                    '5A - 黃靖琳',
                    '5B - 鄭裕琳',
                    '5C - WAQAS YOUNIS',
                ],
            },
            {
                from: 6,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-nov/f6.jpg',
                studentName: [
                    '6A - 李德勤',
                    '6B - RAI NORAH'
                ],
            },
        ],
    },

    {
        id: 'decjan',
        title: '十二月至一月之星',
        etitle: 'Stars of Dec to Jan',
        thumbnail:
            'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f1-web.jpg',
        // https://drive.google.com/file/d/1UZPwIoTzFFtKr_K5YElCTxiCfSi5cxlC/view?usp=share_link
        students: [
            {
                from: 1,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f1-web.jpg',
                studentName: ['1A - 陳思思', '1B - 吳晓藍', '1C - LIMBU LALGIE'],
            },
            {
                from: 2,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f2-web.jpg',
                studentName: [
                    '2A - 黃芷萱',
                    '2B - SIAKI MAIKELI LAGILAGI',
                ],
            },
            {
                from: 3,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f3-web.jpg',
                studentName: [
                    '3A - 陳冠峯',
                    '3B - 鄧梓標',
                    '3C - PUN RAZ',
                ],
            },
            {
                from: 4,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f4-web.jpg',
                studentName: ['4A - 徐健鋒', '4B - 王祖迪', '4C-GURUNG ASHESH'],
            },
            {
                from: 5,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f5-web.jpg',
                studentName: [
                    '5A - 薛欣純',
                    '5B - 陳嘉琪',
                    '5C - MALIK HINA',
                ],
            },
            {
                from: 6,
                url: 'https://www.ypicallmc.edu.hk/storage/star-of-month/2023-dec-jan/f6-web.jpg',
                studentName: [
                    '6A - 施鴻毅',
                    '6B - GURUNG ALBIN'
                ],
            },
        ],
    }
    // {
    //     id: 'feb',
    //     title: '二月之星',
    //     etitle: 'Stars of Feb',
    //     thumbnail:
    //         'https://ypicallmc.ddns.net:3001/star-of-month/2023-feb/F1.JPG',
    //     // https://drive.google.com/file/d/1UZPwIoTzFFtKr_K5YElCTxiCfSi5cxlC/view?usp=share_link
    //     students: [
    //         {
    //             from: 1,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-feb/F1.JPG',
    //             studentName: ['1A - 陳志勇', '1B - GURUNG PRAJWAL'],
    //         },
    //         {
    //             from: 2,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-feb/F2.JPG',
    //             studentName: [
    //                 '2A - 黃  楠',
    //                 '2B - 李浚杰',
    //                 '2C - ALI MUHAMMAD',
    //             ],
    //         },
    //         {
    //             from: 3,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-feb/F3.JPG',
    //             studentName: [
    //                 '3A - 李仲言',
    //                 '3B - NAVALTA MARC RESTER LOPEZ',
    //                 '3C - MURAD MUQADDIS',
    //             ],
    //         },
    //         {
    //             from: 4,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-feb/F4.JPG',
    //             studentName: ['4A - 楊可盈', '4B - 鄭裕琳', '4C-GURUNG LUCAS B'],
    //         },
    //         {
    //             from: 5,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-feb/F5.JPG',
    //             studentName: [
    //                 '5A - 譚惠馨',
    //                 '5B - RANA REGAL',
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: 'mar',
    //     title: '三月至四月之星',
    //     etitle: 'Stars of Mar to Apr',
    //     thumbnail:
    //         'https://ypicallmc.ddns.net:3001/star-of-month/2023-apr/F1.JPG',
    //     // https://drive.google.com/file/d/1UZPwIoTzFFtKr_K5YElCTxiCfSi5cxlC/view?usp=share_link
    //     students: [
    //         {
    //             from: 1,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-apr/F1.JPG',
    //             studentName: ['1A - 黃子謙', '1B - PASCUAL KINFER ZION GENESIS SELECCION'],
    //         },
    //         {
    //             from: 2,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-apr/F2.JPG',
    //             studentName: [
    //                 '2A - 鄭湘悠',
    //                 '2B - 楊雅茜',
    //                 '2C - JAVED SIMRAN',
    //             ],
    //         },
    //         {
    //             from: 3,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-apr/F3.JPG',
    //             studentName: [
    //                 '3A - 羅海欣',
    //                 '3B - LIMBU ANUSH',
    //                 '3C - BUNSRI KALIN',
    //             ],
    //         },
    //         {
    //             from: 4,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-apr/F4.JPG',
    //             studentName: ['4A - 張植嘉', '4B - 郭金慧', '4C - MALIK HINA'],
    //         },
    //         {
    //             from: 5,
    //             url: 'https://ypicallmc.ddns.net:3001/star-of-month/2023-apr/F5.JPG',
    //             studentName: [
    //                 '5A - 文家豪',
    //                 '5B - LINDSEY JERIMIAH D.',
    //             ],
    //         },
    //     ],
    // }


    
];

export default starOfMonthData;
