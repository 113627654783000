import { createContext, useState } from 'react';
import starOfMonthData from '../data/starOfMonthData';

const HomeContext = createContext();

export const HomeProvider = ({ children }) => {
    const [showCht, setShowCht] = useState(false);
    const [monthList, setMonthList] = useState(starOfMonthData);

    const changeLang = () => {
        setShowCht(!showCht);
    };

    return (
        <HomeContext.Provider value={{ showCht, monthList, changeLang }}>
            {children}
        </HomeContext.Provider>
    );
};

export default HomeContext;
